import React from 'react'
import { ServiceWrapper } from './Styled'
import ServiceAchive from './ServiceAchive'
import { serviceListData, trainerList } from '../../data/Data'
import { Col, Container, Row, } from 'react-bootstrap'
import { ContactButton } from '../../Styled/CustomElements'
import { Ball, ButtonIcon, ServiceBoy, ServiceVector, TrainerImage1 } from '../../Styled/AllImages'
import { Box, LazyImage, MainHeading, SpanStyled, SubHeadingStyled, TextStyled } from '../../Styled/Elements'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer';
import { Animate } from 'react-simple-animate'

const Service = () => {
    const { t, i18n } = useTranslation();
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    // Determine text direction (RTL or LTR)
    const isRTL = i18n.dir() === 'rtl';

    // Define styles based on text direction
    const start1 = isRTL
        ? { opacity: 0, transform: 'translateX(350px)' }
        : { opacity: 0, transform: 'translateX(-350px)' };
    const start2 = isRTL
        ? { opacity: 0, transform: 'translateX(-350px)' }
        : { opacity: 0, transform: 'translateX(350px)' };

    const end = { opacity: 1, transform: 'translateX(0)' };

    const WhatsApp = () => {
        const phoneNumber = '+966580911057';
        const message = 'Hello, Advanced Housing';
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    return (
        <>
            <ServiceWrapper className='service-bg common-space' id='trainer'>
                <Container>
                    <Row className='align-items-center'>
                        <Col lg={6}>
                            <Box className='service-content'>
                                <MainHeading className='heading-main darkGradient'> {t('Our_Trainers')} </MainHeading>
                                <SubHeadingStyled className='heading-mini'> {t('Developing_Talent')} </SubHeadingStyled>
                            </Box>
                        </Col>
                        <Col lg={6} className='trainer-col'>
                            <Box className='service-vector'>
                                <LazyImage src={ServiceVector} />
                            </Box>
                        </Col>
                    </Row>

                    <Row className='row-gap'>
                        {
                            trainerList.map((list, index) => (
                                <Col key={index} lg={4} md={6} sm={12}>
                                    <Box className='trainer-wrapper'>
                                        <Box className='trainer-img'>
                                            {list.image}
                                        </Box>
                                        <Box className='trainer-content'>
                                            <MainHeading size='24px' className='darkGradient'>{t(list.heading)}</MainHeading>
                                            <TextStyled size='14px' >{t(list.label)}</TextStyled>
                                            <Box className='tainer-content-list'>
                                                <LazyImage src={Ball} />
                                                <TextStyled size='14px'>{t(list.text)}</TextStyled>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Col>
                            ))
                        }

                    </Row>
                </Container>
            </ServiceWrapper >
            <ServiceAchive />
        </>
    )
}

export default Service