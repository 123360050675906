import React from 'react'
import { FooterWapper } from './Styled'
import { Container, Row } from 'react-bootstrap'
import { Box, SpanStyled } from '../Styled/Elements'
import { FoooterLogo, FooterInstagram, FooterLinkedin, FooterSnapchat, FooterTikTok, FooterTwitter } from '../Styled/AllImages'
import { NavDataConfig } from '../data/Data'
import { Link } from 'react-scroll'
import { useTranslation } from 'react-i18next'

const Footer = () => {
    const { t, i18n } = useTranslation();
    const FowlWeb = () => {
        window.open('https://websites.fowl.sa/', '_blank');
    };
    const date_year = new Date();
    const socialIcon = [
        {
            img: <FooterTwitter />,
            link: 'https://x.com/star1_academy?t=xmRfBHh45-FzYqW4sWh7vw&s=08',
        },
        {
            img: <FooterInstagram />,
            link: 'https://www.instagram.com/star1_academy2024?igsh=cmZla2VseGtvNnNj',
        },
        {
            img: <FooterSnapchat />,
            link: 'https://www.snapchat.com/add/star1_academy?share_id=_3PrzAZYID8&locale=ar-AE',
        },
        {
            img: <FooterTikTok />,
            link: 'https://www.tiktok.com/@star1_academy?_t=8n2Zm21NwhU&_r=1',
        },
    ]
    return (
        <FooterWapper className='container-fluid border-top-line'>
            <Container>
                <Row>
                    <Box className='d-flex align-items-center justify-content-between gap-2 footer-content'>
                        <Box className='footer-logo'>
                            <FoooterLogo />
                        </Box>
                        <Box className='d-flex flex-column justify-content-center align-items-center gap-1'>
                            <Box className='footer-list'>
                                {NavDataConfig.map((item, key) => (
                                    <Box key={key}>
                                        <Link to={item.to}>{t(item.label)}</Link>
                                    </Box>
                                ))}
                            </Box>
                            <Box className='footer-revised'>
                                <SpanStyled className='text-center' size='13px' color='var(--white)' transform='capitalize'>{t('AllRightReserved2')} &copy; {date_year.getFullYear()} {t('Star_1_Academy')}.  </SpanStyled>
                                <SpanStyled className='text-center' size='13px' color='var(--white)' transform='capitalize'>{t('Developed_by')}
                                    <SpanStyled className='fowl' color='var(--themeColor)' size='14px' weight='bold' onClick={FowlWeb}> {t('Fowl')}</SpanStyled>
                                </SpanStyled>
                            </Box>
                        </Box>
                        <Box className='Social-links'>
                            {
                                socialIcon.map((list, key) => (
                                    <Box key={key} className='contact-links'>
                                        <a href={list.link} target="_blank" rel="noopener noreferrer">
                                            {list.img}
                                        </a>
                                    </Box>
                                ))
                            }
                        </Box>
                    </Box>
                </Row>
            </Container>
        </FooterWapper>
    )
}

export default Footer