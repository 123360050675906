import styled from "styled-components";

export const FooterWapper = styled.footer`
.footer-list{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    & a{
        font-family: var(--regular);
        color: var(--white);
        white-space: nowrap;
        &:hover{
        color: var(--themeColor);
            // background: var(--darkGradient);
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
        }
    }
}
.fowl{
    cursor: pointer;
}
`