
import React, { useEffect } from 'react'
import { BannerWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Box, HeadingStyled, LazyImage, } from '../../Styled/Elements'
import { BannerBg, ParallexBg, Star1 } from '../../Styled/AllImages'
import { ContactButton } from '../../Styled/CustomElements'

const Banner = () => {
    const WhatsApp = () => {
        const phoneNumber = '+966533703343';
        const message = 'Hello,Star 1 Academy';
        const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    const { t } = useTranslation();
    useEffect(() => {
        window.addEventListener('scroll', () => {
            let scrollY = window.scrollY;
            document.documentElement.style.setProperty("--scrollTop", `${scrollY}px`);
        });
    }, []);


    return (
        <BannerWrapper id='home'>
            <Row>
                <Col className='position-relative'>
                    <Box className='header-main'>
                        <Box className='img-layer layer-base' style={{ background: `url(${BannerBg})` }}></Box>
                        <Box className='img-layer layer-mid' style={{ background: `url(${ParallexBg})` }}></Box>
                        <Container>
                            <Box className='layers'>
                                <Box className='layer-head banner-content'>
                                    <HeadingStyled className='caption-sub-heading darkGradient' lh='1'>{t('Kickstart')}</HeadingStyled>
                                    <HeadingStyled size='28px' className='caption-heading-main darkGradient' lh='1'>{t('Your_Journey')}</HeadingStyled>
                                    <HeadingStyled size='36px' className='caption-sm-heading darkGradient' lh='1'>{t('to_Greatness_with')}</HeadingStyled>
                                </Box>
                                <Box className='star-1 layer-base'>
                                    <LazyImage src={Star1} />
                                </Box>

                                <Box className='Banner-board' >
                                    <ContactButton clName='layer-base' label={t('Join_US_Now')} onClick={WhatsApp} />
                                </Box>
                            </Box>
                        </Container>
                    </Box>
                </Col>
            </Row>
        </BannerWrapper>
    )
}

export default Banner