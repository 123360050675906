import styled from "styled-components";

export const ProjectWrapper = styled.section`
.choice-wrapper{
    position: relative;
    padding: 0 0px 55px;
}
.choice-ball{
    display: flex;
    align-items: center;
    justify-content: center;
}
.choice-content{
       
}
.choice-content{

    &> div{
        position:absolute;
        width: 35%;
        display: flex;
        align-items: baseline;
        z-index: 1;
        & p{
            font-size:14px;
            color:var(--themeColor);
        }
        & span{
                position: relative;
                width:82px;
                z-index: 1;
            & img{
                width:100%;
                height:100%;
            }
        }
    }     
}
.choice-1{

    &>div{
        display:flex;
        flex-direction: row-reverse;
        text-align: end; 
        left: 70px;
    }
    
}
.choice-2{

    &>div{
        flex-direction: row;
        text-align: left;
        right: 70px;     
    }
    
}
.choice-3{
    &>div{
        flex-direction: row-reverse;
        text-align: end; 
        left: 70px; 
        bottom: 65px;
    }
    
}
.choice-4{
    &>div{
        flex-direction: row;
        text-align: left;
        right: 70px; 
        bottom: 65px;
    }
    
}
.choice-5{

    &>div{
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        right: 0;
        bottom: -100px;
        left: 0;
        margin: auto;
        & span{
                position: relative;
                width:62px;
                z-index: 1;
            & img{
                width:100%;
                height:100%;
            }
        }
    }
}
.star-left{
    position: absolute;
    bottom: 50px;
}
.star-right{
    position: absolute;
    right:0;
    top: 50px;
}


`
export const ChooseSliderWrapper = styled.section`

.custom-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    margin:25px 0;
}
.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

// .slick-slide {
//   transition: opacity 0.5s ease;
// }

// .slick-slide.slick-current,
// .slick-slide.slick-center {
//   opacity: 1;
// }

// .slick-slide:not(.slick-current):not(.slick-center) {
//   opacity: 0.5;
// }

`
