import React from 'react'
import ContactForm from './ContactForm'
import { ContactWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import { TextButton, TextWithIcon } from '../../Styled/CustomElements'
import { Box, MainHeading, SubHeadingStyled, } from '../../Styled/Elements'
import { ContactVector1, ContactVector2, Email, Phone, location } from '../../Styled/AllImages'
import { useTranslation } from 'react-i18next'
import Footer from '../../footer/Footer'

const Contact = () => {
  const WhatsApp = () => {
    const phoneNumber = '+966533703343';
    const message = 'Hello,Star 1 Academy';
    const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, '_blank');
  };
  const Map = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3817.8078338043424!2d42.580739699999995!3d16.885398!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1607e2e7dc39c927%3A0x3b9fa0741662cd24!2sHYPERPANDA%20PLAYGROUND!5e0!3m2!1sen!2s!4v1718018055895!5m2!1sen!2s"
  const { t } = useTranslation();

  return (
    <>
      <ContactWrapper id='contact' className='contact-bg'>
        <ContactVector1 className='contact-vector-1' />
        <ContactVector2 className='contact-vector-2' />
        <Container>
          <Box className='containerContact'>
            <Row className='common-space'>
              <Box className='content-heading text-center'>
                <MainHeading className='lightGradient heading-main'> {t("Get_in_Touch")} </MainHeading>
                <SubHeadingStyled className='heading-mini' color='var(--white)'>{t("Help_You")}</SubHeadingStyled>
              </Box>
            </Row>
            <Row>
              <Box className='contact-info'>
                <TextButton icon={Phone} label='+966533703343' onClick={WhatsApp} />
                <TextWithIcon icon={Email} title='info@example.sa' href="mailto:info@example.sa" />
                <TextWithIcon icon={location} title='VHPJ+575, As Suways, Jazan 82722, Saudi Arabia' href={Map} />
              </Box>
            </Row>
            <Row>
              <Col lg={6} md={12} className='contact-Us'>
                <Box className='contect-with-us'>
                  <ContactForm />
                </Box>
              </Col>
              <Col lg={6} md={12}>
                <Box className='contect-with-you'>
                  <Box className='contact-box'>
                    <iframe src={Map} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </Box>
                </Box>
              </Col>
            </Row>
          </Box>
        </Container>
        {/* <img className='contact-bm-logo' src={Contactlogo} alt='img' /> */}
        <Footer />
      </ContactWrapper>
    </>
  )
}

export default Contact