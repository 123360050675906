
import About from "../components/sections/about/About"
import Banner from "../components/sections/banner/Banner"
import Client from "../components/sections/client/Client"
import Contact from "../components/sections/contact/Contact"
import Service from "../components/sections/services/Service"
import Projects from "../components/sections/chooseUs/ChooseUs"
import ChooseUs from "../components/sections/chooseUs/ChooseUs"
import GallerySection from "../components/sections/client/GallerySection"
import Header from "../components/header/Header"

const Index = () => {
    return (
        <>
            <Header />
            <Banner />
            <About />
            <Service />
            <ChooseUs />
            <GallerySection />
            <Contact />
        </>
    )
}

export default Index