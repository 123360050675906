import React, { useState } from 'react'
import { ContactFormWrapper } from './Styled'
import { useForm } from "react-hook-form"
import { Box, LabelStyled } from '../../Styled/Elements'
import { ButtonIcon2 } from '../../Styled/CustomElements'
import { ButtonIcon } from '../../Styled/AllImages'
import { useTranslation } from 'react-i18next'
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ContactForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { t, i18n } = useTranslation();
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            email: '',
            phone: '',
            message: '',
            language: i18n.language,
        }
    })
    const getApi = async (data) => {
        try {
            setIsLoading(true);
            data.language = i18n.language;
            const response = await axios.post("https://star1.app4orce.com/api/public/api/contact-us", data);
            if (response.status === 200) {
                reset({
                    name: '',
                    email: '',
                    phone: '',
                    message: '',
                    language: '',
                });
                // toast.success("Your submission was successful.", { autoClose: 3000 });
                toast.success(t('submission'), {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    // className: 'message'
                })
                setIsLoading(false);
            } else {
                toast.error(t('submission_fail'), {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                reset({
                    name: '',
                    email: '',
                    phone: '',
                    message: '',
                    language: '',
                });
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error submitting the form:", error);
            // alert("Submission has failed.");
            toast.error(t('submission_fail'), {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            reset({
                name: '',
                email: '',
                phone: '',
                message: '',
                language: '',
            });
            setIsLoading(false);
        }
    }
    const [phone, setPhone] = useState('');
    const handlePhoneChange = (e) => {
        // Allow only positive numbers
        const input = e.target.value;
        if (!isNaN(input) && input >= 0) {
            setPhone(input);
        }
    };
    const onSubmit = async (data) => {
        console.log(data);
        getApi(data);
    };

    return (
        <>

            <ContactFormWrapper>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box className='field-box'>
                        <LabelStyled className='lightGradient' >{t('name')}</LabelStyled>
                        <input className='input-field' placeholder={t('namePlaceholder')}  {...register("name", { required: true })} aria-invalid={errors.name ? "true" : "false"} />
                        {errors.name?.type === "required" && (<p className='error-message' role="alert">{t('name_error')}</p>)}
                    </Box>
                    <Box className='field-box'>
                        <LabelStyled className='lightGradient' >{t('email')}</LabelStyled>
                        <input className='input-field' placeholder={t('emailPlaceholder')} type='email'{...register("email", { required: true })} aria-invalid={errors.email ? "true" : "false"} />
                        {errors.email && <p className='error-message' role="alert">{t('email_error')}</p>}
                    </Box>
                    <Box className='field-box'>
                        <LabelStyled className='lightGradient' >{t('contactPhone')}</LabelStyled>
                        <input className='input-field' placeholder={t('phonePlaceholder')} type='text' value={phone} {...register("phone", { required: true })} aria-invalid={errors.phone ? "true" : "false"} onChange={handlePhoneChange} />
                        {errors.phone && <p className='error-message' role="alert">{t('phone_error')}</p>}
                    </Box>
                    <Box className='field-box'>
                        <LabelStyled className='lightGradient' >{t('yourMessage')}</LabelStyled>
                        <textarea className='input-field' placeholder={t('messagePlaceholder')} cols="30" rows="5" {...register("message", { required: true })} aria-invalid={errors.message ? "true" : "false"} />
                        {errors.message && <p className='error-message' role="alert">{t('message_error')}</p>}
                    </Box>
                    <Box className='d-flex justify-content-end my-3 contact-btn'>
                        {isLoading ? <p className='loading-btn'><span className='spinner'></span></p> : null}
                        <ButtonIcon2 clName='gradient' type="submit" icon={ButtonIcon} label={t('Send')} />
                        {/* <p className='loading-btn'><span className='spinner'></span></p> */}
                    </Box>
                </form>
                <ToastContainer />

            </ContactFormWrapper >
        </>
    )
}

export default ContactForm