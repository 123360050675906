import React from 'react'
import { ChooseSliderWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, LazyImage, MainHeading, SpanStyled, SubHeadingStyled, TextStyled } from '../../Styled/Elements'
import { starSlider } from '../../data/Data'
import Slider from 'react-slick'
import { ButtonIcon2 } from '../../Styled/CustomElements'
import { BorderImg, ButtonIcon } from '../../Styled/AllImages'
import { useTranslation } from 'react-i18next'

const ChooseSliders = () => {
    const { t } = useTranslation();
    const WhatsApp = () => {
        const phoneNumber = '+966533703343';
        const message = 'Hello,Star 1 Academy';
        const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    const settings = {
        dots: false,
        arrows: false,
        className: "center",
        centerMode: true,
        centerPadding: "0",
        slidesToShow: 4,
        speed: 1000,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: false,
        responsive: [

            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
        afterChange: (index) => {
            // Find all the slides
            const slides = document.querySelectorAll('.slick-slide');
            slides.forEach(slide => slide.classList.remove('slick-current'));

            // Add slick-current class to the center slides
            const centerSlideIndex = Math.floor(slides.length / 2);
            slides[index].classList.add('slick-current');
            if (slides[index + 1]) {
                slides[index + 1].classList.add('slick-current');
            }
        }
    };
    return (
        <ChooseSliderWrapper className='slider-bg'>
            <Container>
                <Row>
                    <Col>
                        <Box className='common-space d-flex flex-column align-items-center justify-content-center'>
                            <MainHeading className='lightGradient heading-main'> {t('Stars_Who_Shined')} </MainHeading>
                            <SubHeadingStyled className='lightGradient heading-mini'> {t('Club_Recruits')} </SubHeadingStyled>
                        </Box>
                    </Col>
                </Row>
                <Row>
                    <Slider {...settings}>
                        {
                            starSlider.map((list, index) => (
                                <Box className='d-flex flex-column align-items-center justify-content-center text-center gap-3'>
                                    <Box>
                                        {list.img}
                                    </Box>
                                    <Box>
                                        <MainHeading size='24px' className='lightGradient'> {t(list.heading)} </MainHeading>
                                        <TextStyled className='lightGradient'> {t(list.text)} </TextStyled>
                                    </Box>
                                </Box>
                            ))
                        }
                    </Slider>
                    <ButtonIcon2 label={t('Join_US_Now')} icon={ButtonIcon} onClick={WhatsApp} />
                </Row>
            </Container>
            <Box className='border-image'>
                <SpanStyled>
                    <img src={BorderImg} alt='border-img' />
                </SpanStyled>
            </Box>
        </ChooseSliderWrapper>
    )
}

export default ChooseSliders