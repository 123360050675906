import React, { useCallback, useState } from 'react'
import { GalleryWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import Carousel, { Modal, ModalGateway } from "react-images";
import { Box, LazyImage, MainHeading, SubHeadingStyled, TextStyled } from '../../Styled/Elements'
import { ButtonIcon } from '../../Styled/AllImages'
import { ContactButton } from '../../Styled/CustomElements'
import { useTranslation } from 'react-i18next';
import { photos } from '../../data/Data';
import Client from './Client';
import { Image, Shimmer } from 'react-shimmer'

const GallerySection = () => {
    const WhatsApp = () => {
        const phoneNumber = '+966533703343';
        const message = 'Hello,Star 1 Academy';
        const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    const { t } = useTranslation();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };
    return (
        <GalleryWrapper className='gallery-bg' >
            <Container id='gallery'>
                <Row>
                    <Col lg={6} md={12}>
                        <Box className='gallery-content'>
                            <MainHeading className='darkGradient heading-main'> {t('OUR_GALLERY')} </MainHeading>
                            <SubHeadingStyled className='heading-mini'> {t('Moments_Training')} </SubHeadingStyled>
                            <TextStyled> {t('OUR_GALLERY_text')} </TextStyled>
                            <ContactButton icon={ButtonIcon} label={t('Join_US_Now')} onClick={WhatsApp} />
                        </Box>
                    </Col>
                    <Col className='upper-grid'>
                        {
                            photos.slice(0, 2).map((items, index) => (
                                <Box key={index}>
                                    <Box className={`gallery-items gallery-${items.className}`} onClick={(event) => openLightbox(event, { photo: items, index })} >
                                        {/* <LazyImage className={`${items.className}`} src={items.src} /> */}
                                        <Image
                                            src={items.src}
                                            fallback={<Shimmer />}
                                        />
                                    </Box>
                                </Box>
                            ))
                        }
                    </Col>
                </Row>
                <Row>
                    <Box className='gallery-grid'>
                        {
                            photos.slice(2, 8).map((items, index) => (
                                <Box key={index} className={`gallery-img-wraping img-${items.className}`} onClick={(event) => openLightbox(event, { photo: items, index })} >
                                    {/* <LazyImage className={`${items.className}`} src={items.src} /> */}
                                    <Image
                                        src={items.src}
                                        fallback={<Shimmer />}
                                    />
                                </Box>
                            ))
                        }
                    </Box>
                </Row>
                <ModalGateway>
                    {viewerIsOpen ? (
                        <Modal onClose={closeLightbox}>
                            <Carousel
                                currentIndex={currentImage}
                                views={photos.map(x => ({
                                    ...x,
                                    srcset: x.srcSet,
                                    caption: x.title
                                }))}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </Container>
            {/* <Client /> */}
        </GalleryWrapper>
    )
}

export default GallerySection