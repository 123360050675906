import React from 'react'
import { AchivementWrapper } from './Styled'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, LazyImage, MainHeading } from '../../Styled/Elements'
import { BachImg, BachLogo, ButtonIcon } from '../../Styled/AllImages'
import { ButtonIcon2 } from '../../Styled/CustomElements'

const ServiceAchive = () => {
    const { t } = useTranslation();
    const WhatsApp = () => {
        const phoneNumber = '+966533703343';
        const message = 'Hello,Star 1 Academy';
        const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };

    return (
        <AchivementWrapper className=' common-space achieve-bg'>
            <Container>
                <Row className='align-items-center'>
                    <Col lg={5} md={8}>
                        <Box className='achived-content-wrapper'>
                            <MainHeading size='36px' className='lightGradient sub-heading' lh='1'> {t('Building')} </MainHeading>
                            <MainHeading className='heading-main lightGradient' lh='1'>{t("Saudi_Arabia")}</MainHeading>
                            <MainHeading size='46px' className=' heading-mini lightGradient' lh='1'> {t('Football_Future')} </MainHeading>
                            <ButtonIcon2 label={t('Join_US_Now')} icon={ButtonIcon} onClick={WhatsApp} />
                        </Box>
                    </Col>
                    <Col lg={3} md={4}>
                        <Box className='bach-logo'>
                            <LazyImage src={BachLogo} />
                        </Box>
                    </Col>
                    <Col lg={4}>
                        <Box className='bach-img'>
                            <LazyImage src={BachImg} />
                        </Box>
                    </Col>
                </Row>
            </Container>
        </AchivementWrapper>
    )
}

export default ServiceAchive