import styled from 'styled-components'
import { Box, HeadingStyled, Span, SpanStyled, TextStyled } from './Elements';


export const sliceProps = (props, sub) => {
    const { children, ...otherProps } = props;
    sub.forEach(prop => delete otherProps[prop]);
    return otherProps;
}

export const ButtonWithIcon = (props) => {
    const unwantedProps = ['icon', 'label'];
    return (
        <ButtonWrapper {...sliceProps(props, unwantedProps)} className='d-flex align-items-center'>
            <button className={`header-btn ${!props.icon ? 'Btn-icon' : ''} `} autoComplete='false'>
                {props.icon && (
                    <props.icon width='20px' />
                )}
                <SpanStyled color='var(--white)' size='14px'>{props.label ? props.label : ''}</SpanStyled>
            </button>
        </ButtonWrapper>
    )
}

export const ContactButton = (props) => {
    const unwantedProps = ['icon', 'label', 'clName'];
    return (
        <ContactWrapper className={`  ${props.clName ? props.clName : ''}`}>
            <button {...sliceProps(props, unwantedProps)} className={`click-btn ${!props.icon ? 'Btn-icon' : ''}`} autoComplete='false'>
                <HeadingStyled color='var(--white)' transform='math-auto' size='14px' weight='normal'>{props.label ? props.label : ''}</HeadingStyled>
                {props.icon && (
                    <props.icon width='22px' />
                )}
            </button>
        </ContactWrapper>
    )
}
export const ButtonIcon2 = (props) => {
    const unwantedProps = ['icon', 'label'];
    return (
        <ButtonIconWrap className='custom-btn' {...sliceProps(props, unwantedProps)}>
            <button className={`click-btn ${!props.icon ? 'Btn-icon' : ''}`} autoComplete='false'>
                <HeadingStyled color='var(--white)' transform='math-auto' size='14px' weight='normal'>{props.label ? props.label : ''}</HeadingStyled>
                {props.icon && (
                    <props.icon width='22px' />
                )}
            </button>
        </ButtonIconWrap>
    )
}
export const InputWithIcon = (props) => {
    const unwantedProps = ['icon', "label"];
    return (
        <InputStyledWrap className='position-relative' >
            <InputStyled {...sliceProps(props, unwantedProps)} className={`w-100 ${!props.icon ? 'no-icon' : ''}`} autoComplete='false' />
            {props.icon && (
                <props.icon width='18px' className='position-absolute input-svg' />
            )}
        </InputStyledWrap>
    )
}
export const TextWithIcon = (props) => (
    <TextWithStyledWrapper color={props.color} className={`d-flex ${props.desc ? 'align-items-start' : 'align-items-center'}`}>
        <Box className='mx-2 d-flex align-items-center justify-content-center contact-svg'>
            <props.icon width='26px' />
        </Box>
        <Box>
            <Box className='mb-0 contact-text' size={props.headingSize ? props.headingSize : '14px'}
                color={!props.alsotextColor ? 'var(--white)' : 'var(--white)'}
                dangerouslySetInnerHTML={{ __html: props.title }}>
            </Box>
            {props.desc ?
                <TextStyled className='text-uppercase' color={'var(--white)'} size='14px' >
                    {props?.desc}
                </TextStyled>
                : null}
        </Box>
    </TextWithStyledWrapper>
)

const TextWithStyledWrapper = styled.div`
  svg {
      [stroke] {
          stroke: ${({ color }) => color ? color : ''};
      }
  }
`
export const InputStyled = styled.input`
    background:var(--bgColor);
    border: none;
    border-radius: 8px;
    height: 43px;
    // color: var(--textGrey);
    &:not(.no-icon) {
        padding-left: 50px;
    }
    &.no-icon {
        padding-left: 18px;
    }
    padding-right: 18px;
    outline: none;
    &,&::placeholder {
        font-size: 14px;
        color: var(--textPalceholder);
    }
`
export const InputStyledWrap = styled.div`
    border-radius: 8px;
    background: var(--white);
    svg {
    left: 13px;
    top: 12px;
    height: 20px;
    }
`


export const ContactWrapper = styled.div`
button{
    display: flex;
    align-items: center;
    justify-content:${props => props.content ? props.content : ''};
    gap: 6px;
    padding: 10px 15px;
    border: none;
    border-radius: 8px;
    background: var(--darkGradient);
    color: var(--white);
    text-transform: Uppercase;
    cursor: pointer;
    transform: scale(0.9);
    transition: all ease-in-out 0.4s;
    &:hover{
        transform: scale(1);
        background: var(--darkGradient);
        
    }
}
    
    
`
export const ButtonIconWrap = styled.div`
button{
    display: flex;
    align-items: center;
    justify-content:${props => props.content ? props.content : ''};
    gap: 6px;
    padding: 10px 15px;
    border: none;
    border-radius: 8px;
    background: transparent;
    border: 1px solid white;
    color: var(--white);
    text-transform: Uppercase;
    cursor: pointer;
    // transform: scale(0.9);
    transition: all ease-in-out 0.4s;
    margin:15px 0;
    &:hover{
        // transform: scale(1);
        background: var(--darkGradient);
        border:1px solid var(--themeColor);
        
    }
}
    
    
`
export const ButtonWrapper = styled.div`
button,
button::after {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

button {
    background: none;
    border: 1px solid var(--darkColor);
    border-radius: 8px;
    color: var(--darkColor);
    display: flex;
    gap:8px;
    font-size: 15px;
    padding: 10px 14px;
    position: relative;
    text-transform: uppercase;
    & svg{
        [fill]{
            fill:var(--darkColor);
        }
    }

}

button::before,
button::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--darkGradient);
}
button::before{
    background: transparent;
}

button:hover {
    border: 1px solid var(--themeColor);
    color: var(--white);
    & svg{
        [fill]{
            fill:var(--white);
        }
    }
}

  
`
export const TextButton = (props) => {
    const unwantedProps = ['icon', 'label', 'bgBtn', 'clName', 'clNameMain'];
    return (
        <TextButtonWrapper {...sliceProps(props, unwantedProps)} className={`d-flex align-items-center btn-text ${props.clNameMain ? props.clNameMain : ''}`}>
            <button className={` ${props.clName ? props.clName : ''} ${props.bgBtn ? props.bgBtn : ''} ${!props.icon ? 'Btn-icon' : ''} `} autoComplete='false'>
                {props.icon && (
                    <props.icon width='28px' />
                )}
                <SpanStyled dir='ltr' size='16px' >{props.label ? props.label : ''}</SpanStyled>

            </button>
        </TextButtonWrapper>
    )
}
const TextButtonWrapper = styled.div`

.btn-text {
    background: var(--gradient);
    padding: 8px 12px;
    border-radius: 8px;
    
}
& button{
    background:none;
    border:none;
    display:flex;
    align-items:center;
    gap:6px;

    & span{
        color:var(--white);
        font-family:var(--arRegular);
    }
}
`