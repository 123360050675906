import styled from "styled-components";

export const AboutWrapper = styled.section`
.about-content{
  padding-top:160px;
  padding-bottom:180px;
    
}
.video-thumb{
    &>span{
        &>img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-top-left-radius: 50px;
            border-bottom-right-radius: 50px;
        }
    }
}
.house-bird{
    position: absolute;
    right:-20px;
    top:200px;
    z-index: 2;
}
.about-vector{
    position: absolute;
    right: 0;
    top: 50px;
}

`

export const AboutValueWrapper = styled.section` 
.common-space{
    padding:50px 0 150px;
}
.about-theme-design{
    position: absolute;
    right: 0;
    top: -70px;
}
`