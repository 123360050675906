import Index from "./Index";
import ErrorPage from "../components/Page404/ErrorPage";

export const privateRoute = [
    {
        path: "/",
        element: <Index />,
    },
    {
        path: "*",
        element: <ErrorPage />,
    },
]