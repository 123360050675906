import styled from 'styled-components'
import { Ball } from '../Styled/AllImages'


export const HeaderContainer = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100%;
    // transition: all 0.5s cubic-bezier(.77,0,.18,1);
    .nav-bar{
        display:flex;  
        align-items: center;
        justify-content: space-between;
    }
    .nav-bar-list{
        display:flex;  
        align-items: center;
        gap: 20px;
    }
    .nav-bar-list a{
        color:var(--white);
        padding-bottom: 12px;
        position: relative;
        font-size:14px;
        font-family: var(--semibold);
        text-transform: uppercase;
        padding: 4px 12px;
        border-radius: 4px;
        transition: all .4s ease-in-out;
        &:hover, &.active, &:focus{
            background: var(--darkGradient);
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
            padding: 4px 12px;
            border-radius: 4px;
            color:var(--white);
            &::before, &.active::before{
                content: '';
                position: absolute;
                top: 45px;
                bottom: 0;
                right: 0;
                left: 0;
                margin: auto;
                width: 16px;
                height: 16px;
                background: var(--darkGradient);
                background-image: url(${Ball});
            }
            &::after, &.active::after{
                content: '';
                position: absolute;
                top: 60px;
                bottom: 0;
                right: 0;
                left: 0;
                margin: auto;
                width: 25px;
                height: 3px;
                background: var(--darkGradient);
            }
        }
    }
    .logo{
        cursor:pointer;
        svg{
            width: 80px;
        }
    }
    &.sticky{
        background: #7cc2cc;
        // background: var(--darkColor);
        box-shadow: 0px 3px 10px #07758473;
        z-index: 200;
    }
    .toggleBtn{
        display: none;
    }
}

.Social-links {
    display: flex;
    gap: 12px;
    cursor: pointer;
}
`