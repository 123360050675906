import React, { useEffect, useRef } from 'react'
import { AboutWrapper } from './Styled'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-bootstrap'
import { ContactButton } from '../../Styled/CustomElements'
import { Box, LazyImage, MainHeading, SpanStyled, SubHeadingStyled, TextStyled } from '../../Styled/Elements'
import { AboutHouse2, BorderImg, ButtonIcon, ThemeVector, Video, VideoThumb } from '../../Styled/AllImages'
import { useInView } from 'react-intersection-observer'
import { Animate } from 'react-simple-animate'
import { Player, ControlBar, PlayToggle } from 'video-react';
import "video-react/dist/video-react.css";


const About = () => {
    const { t, i18n } = useTranslation();
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    const isRTL = i18n.dir() === 'rtl';
    const start = isRTL
        ? { opacity: 0, transform: 'translateX(-350px)' }
        : { opacity: 0, transform: 'translateX(350px)' };

    const end = { opacity: 1, transform: 'translateX(0)' };
    const WhatsApp = () => {
        const phoneNumber = '+966533703343';
        const message = 'Hello,Star 1 Academy';
        const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    const playerRef = useRef(null);

    useEffect(() => {
        if (playerRef.current) {
            playerRef.current.actions.play();
        }
    }, []);

    return (
        <AboutWrapper id='about' className='about-bg'>
            <ThemeVector className='about-vector' />
            <Container>
                <Box className='about-content'>
                    <Row className='row-gap'>
                        <Col className='position-relative' lg={6} md={12}>
                            <Box className='video-thumb'>
                                <Player autoPlay muted poster={VideoThumb} src={Video}>
                                    <ControlBar autoHide={false} disableDefaultControls={true}>
                                        <PlayToggle />
                                    </ControlBar>
                                </Player>
                            </Box>
                            <Box className='house-bird'>
                                <LazyImage className='' src={AboutHouse2} />
                            </Box>
                        </Col>
                        <Col lg={6} md={12}>
                            <Box ref={ref} className=' artical-main  mb-0'>
                                <Animate play={inView} duration={1} start={start} end={end}>
                                    <Box className='about-animate'>
                                        <MainHeading className='heading-main darkGradient'>{t('About_Star_1')}</MainHeading>
                                        <SubHeadingStyled className='heading-mini'>{t('Shaping_Tomorrow')}</SubHeadingStyled>
                                        <TextStyled>{t('Shaping_Tomorrow_content')}</TextStyled>
                                        <ContactButton icon={ButtonIcon} label={t('Join_US_Now')} onClick={WhatsApp} />
                                    </Box>
                                </Animate>
                            </Box>
                        </Col>

                    </Row>
                </Box>
            </Container>
            <Box className='border-image'>
                <SpanStyled>
                    <img src={BorderImg} alt='border-img' />
                </SpanStyled>
            </Box>
        </AboutWrapper>
    )
}

export default About