import styled from "styled-components";

export const ClientWrapper = styled.section`

.client-content{
    display: flex !important;
    flex-direction:column;
    align-items: start; 
}
.partner-gallery-img{
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius:8px;
}
.partner-grid{
    height: auto !important;
    display: grid;
    width: 100%;
    gap: 15px;
    margin-top: 30px;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(2, 130px);
}



`
export const GalleryWrapper = styled.section`
padding-bottom: 70px;
.upper-grid{
    display: flex;
    flex-direction: row-reverse;
    align-items: end;
    gap: 15px;
}
.gallery-item-1{
    border-radius: 20px 20px 20px 0;
    &>img{
        border-radius: 20px 20px 20px 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
}
.gallery-item-2{
    border-radius: 20px 0 0 20px ;
    &>img{
    border-radius: 20px 0 0 20px ;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.gallery-img-wraping{
    
        & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
}
.gallery-grid{
    height: auto !important;
    display: grid;
    width: 100%;
    gap: 15px;
    margin-top: 30px;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 160px);
}
.img-item-3{
    grid-column: 1 / 1;
    grid-row: 2 / 2;
    
    &>img{
    border-radius: 0 0 0 20px ;
    }
}
.img-item-4{
    grid-column: 2 / 2;
    grid-row: 1 / 3;
    &>img{
        border-radius: 20px 0 20px 0 ;
    }
}
.img-item-5{
    grid-column: 3 / 3;
    grid-row: 1 / 1;
    &>img{
        border-radius:  0 20px 20px ;
    }
}
.img-item-6{
    grid-column: 3 / 3;
    grid-row: 2 / 2;
    &>img{
        border-radius: 0 20px 20px  ;
    }
}
.img-item-7{
    grid-column: 4 / 4;
    grid-row: 1 / 3;
    &>img{
        border-radius: 20px 20px 0 20px  ;
    }
}
.img-item-8{
    grid-column: 5 / 8;
    grid-row: 1 / 3;
    &>img{
        border-radius: 20px 20px 20px 0  ;
    }
}
`