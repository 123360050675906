
import React from 'react'
import { ProjectWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import { ChooseBall, StartLeft, StartRight } from '../../Styled/AllImages'
import { Box, LazyImage, MainHeading, SubHeadingStyled, TextStyled } from '../../Styled/Elements'
import { useTranslation } from 'react-i18next'
import { choiceData } from '../../data/Data'
import ChooseSliders from './ChooseSliders'
import { useInView } from 'react-intersection-observer'
import { Animate, AnimateGroup } from 'react-simple-animate'

const ChooseUs = () => {
    const { t, i18n } = useTranslation();
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    const isRTL = i18n.dir() === 'rtl';
    const start = isRTL
        ? { opacity: 0, transform: "translateX(-15px)" }
        : { opacity: 0, transform: "translateX(15px)" };
    const end = { opacity: 1, transform: "translateX(0)" };

    return (
        <>
            <ProjectWrapper id='chooseUs' className='chosse-bg'>
                <Box className='star-left'>
                    <LazyImage src={StartLeft} />
                </Box>
                <Box className='star-right'>
                    <LazyImage src={StartRight} />
                </Box>
                <Container>
                    <Row>
                        <Col>
                            <Box className='common-space d-flex flex-column align-items-center justify-content-center'>
                                <MainHeading className='darkGradient heading-main'>{t("Winning_Choice")}</MainHeading>
                                <SubHeadingStyled className='heading-mini'>{t("Excellence_in_Every_Kick")}</SubHeadingStyled>
                            </Box>
                        </Col>
                    </Row>
                    <Row>
                        <Box ref={ref} className='choice-wrapper'>
                            <Box className='choice-content-itmes'>
                                {
                                    choiceData.map((list, index) => (

                                        <Box key={index} className={`choice-content ${list.className}`}>
                                            <AnimateGroup play={inView}>
                                                <Animate
                                                    delay={list.delay}
                                                    sequenceIndex={list.sequenceIndex}
                                                    start={start}
                                                    end={end}
                                                >
                                                    <Box className={`choice-img-${list.className}`}>
                                                        {list.image}
                                                    </Box>
                                                    <Box>
                                                        <MainHeading size="32px" className='main-heading darkGradient'> {t(list.heading)} </MainHeading>
                                                        <TextStyled> {t(list.text)} </TextStyled>
                                                    </Box>
                                                </Animate>
                                            </AnimateGroup>
                                        </Box>
                                    ))
                                }
                            </Box>

                            <Box className='choice-ball'>
                                <LazyImage src={ChooseBall} />
                            </Box>
                        </Box>
                    </Row>
                </Container >
            </ProjectWrapper >
            <ChooseSliders />
        </>
    )
}

export default ChooseUs