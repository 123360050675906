import styled from "styled-components";
import { TrainerBg } from "../../Styled/AllImages";

export const ServiceWrapper = styled.section`
Padding-bottom:100px;
.service-vector{
    position: relative;
    bottom: 90px;
}
.trainer-wrapper{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(145deg, rgba(255,255,255,0.3) 0% ,rgba(255,255,255,1) 100%);
    border-top-left-radius: 40px;
    border-bottom-right-radius: 40px;
    border-left: 1px solid white;
    border-top: 1px solid white;
    height: 230px;
    &:after{
        content: '';
        position: absolute;
        right: 0;
        width: 65px;
        height: 250px;
        background: url(${TrainerBg});
        background-repeat: no-repeat;
        background-size: cover;
    }

}
.trainer-img{
    position: relative;
    width: 55%;
    height: 100%;
    &>Span{
        position: absolute;
    width: 150px;
    bottom: 0px;
    left: -1px;
    }
}
.tainer-content-list{
    display: flex;
    align-items: baseline;
    gap: 10px;

}
.row-gap{
    row-gap:50px;
}


`
export const AchivementWrapper = styled.div`
// margin:120px 0 0;
padding:80px 0;
.bach-img{
    position: absolute;
    bottom: 0px;
    width: 400px;
}
.achived-content-wrapper{
    display: flex;
    flex-direction: column;
    position: relative;
    right: 0px;
}

`