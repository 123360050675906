import styled from "styled-components";

export const BannerWrapper = styled.section`
    .text-styled{
        position: relative;
        right: 15px;
        top: 10px;
    }
    .down-btn{
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 10;
        width: 90px;
        bottom: -10px;
    }
    .banner-content{
        position: relative;
        left: 200px;
        bottom: 100px;
     }

`