import styled from "styled-components";

export const ContactWrapper = styled.section`
.containerContact{
    padding-top:80px;
}
position:relative;
.bg-setting{
 border-radius:12px;
 position:relative;
}
.contact-info{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
    
.btn-text{
    background: var(--gradient);
    padding: 8px 12px;
    border-radius: 8px;
    & div{
        color:var(--white);
    }
    // & svg{
    //     [fill]{
    //         fill:var(--white);
    //     }
    // }
}

.contact-Us{
    border-radius:10px;
}
.contact-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    position: relative;
    padding:20px 20px;
}
.contact-bm-logo{
    width:100%;
    position: relative;
    bottom: 5px;
}
.contact-text{
    color:var(--white);
}
.contact-vector-1{
    position: absolute;
    top: 80px;
    right: 0;
}
.contact-vector-2{
    position: absolute;
    bottom: 230px;
    left: 0;
}
    
`
export const ContactFormWrapper = styled.div`
.field-box{
    display: flex;
    flex-direction: column;
    gap:8px;
    margin:20px 0;
}
.input-field,
.file-attached{
    padding: 10px 15px;
    border-radius: 10px;
    border: none;
    outline: none;
    color:var(--themeColor);
    background: var(--lightGradient);

}

.input-field::placeholder{
    color:var(--textPalceholder);
}
.error-message{
    font-family: var(--arRegular);
    /* border: 1px solid #ff00004f; */
    color: var(--white) !important;
    padding: 6px 8px;
    border-radius: 8px;
    background: #ff00004f;
    -webkit-text-fill-color: white;
}
.loading-btn{
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 20%;
    z-index: 10;
    background: #0b0b0b6b;
    text-align: center;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color:var(--white);
    font-family:var(--regular);

}
.contact-social-links{
    position: relative;
    left: 15px;
}

`