import { Link } from 'react-scroll'
import { Box } from '../Styled/Elements'
import { HeaderContainer } from './Styled'
import { NavDataConfig } from '../data/Data'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BsJustify, BsX } from "react-icons/bs"
import { Container, Row } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { FoooterLogo, HeadPhone, Instagram, Linkedin, Logo, Snapchat, Tiktok, Twitter } from '../Styled/AllImages'
import { ButtonWithIcon } from '../Styled/CustomElements'
import ChangeButton from '../../Languages/ChangeButton'

const Header = () => {
    const navigate = useNavigate()
    const WhatsApp = () => {
        const phoneNumber = '+966533703343';
        const message = 'Hello,Star 1 Academy';
        const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };
    const { t } = useTranslation();
    const [isSticky, setIsSticky] = useState(false);
    const [toggle, setToggle] = useState(false);

    const handleToggle = () => {
        setToggle(!toggle);
        const element = document.querySelector(".nav-bar-list");
        element.classList.toggle("showToggle");
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 1) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const socialIcon = [
        {
            img: <Twitter />,
            link: 'https://x.com/star1_academy?t=xmRfBHh45-FzYqW4sWh7vw&s=08',
        },
        {
            img: <Instagram />,
            link: 'https://www.instagram.com/star1_academy2024?igsh=cmZla2VseGtvNnNj',
        },
        {
            img: <Snapchat />,
            link: 'https://www.snapchat.com/add/star1_academy?share_id=_3PrzAZYID8&locale=ar-AE',
        },
        {
            img: <Tiktok />,
            link: 'https://www.tiktok.com/@star1_academy?_t=8n2Zm21NwhU&_r=1',
        },
    ]
    return (
        <>
            <HeaderContainer className={`border-bottom-line ${isSticky ? 'sticky' : ''} container-fluid py-2`}>
                <Container>
                    <Row>
                        <Box className='nav-bar'>
                            <Box className='logo' onClick={() => navigate('/')}>
                                {/* <Logo /> */}
                                <FoooterLogo />
                            </Box>
                            <Box className='nav-bar-list'>
                                {NavDataConfig.map((item, key) => (
                                    <Box key={key}>
                                        <Link to={item.to} spy={true} smooth={true} offset={0} duration={500} activeClass="active" onClick={handleToggle} >{t(item.label)}</Link>
                                    </Box>
                                ))}
                            </Box>
                            <Box className='d-flex align-items-center gap-3'>
                                <Box className='Social-links'>
                                    {
                                        socialIcon.map((list, key) => (
                                            <Box key={key} className='contact-links'>
                                                <a href={list.link} target="_blank" rel="noopener noreferrer">
                                                    {list.img}
                                                </a>
                                            </Box>
                                        ))
                                    }
                                </Box>
                                <ButtonWithIcon icon={HeadPhone} label='+966533703343' onClick={WhatsApp} />
                                <span className="toggleBtn" onClick={handleToggle}>
                                    {toggle ? <BsX size={30} /> : <BsJustify size={30} />}
                                </span>
                            </Box>
                        </Box>
                    </Row>
                </Container>
                <ChangeButton />
            </HeaderContainer>
        </>
    )
}


export default Header
